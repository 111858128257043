import { Route,Routes } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import Home from './Home';
import Instructeurs from './Instructeurs';
import Lessen from './Lessen';
import Locaties from './Locaties';
import Media from './Media';
import Contact from './Contact';
import './App.css';
import './styles.css';

function App() {
  return (
    <>
    <div className='w-screen h-[calc(100vh-5rem)]' style={{maxWidth:'1000px', margin:'0 auto'}}>
      <Navigation/>
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center" style={{paddingTop:'0px'}}>
        <Routes>        
          <Route path="/" element={<Home/>} />
          <Route path="/instructeurs" element={<Instructeurs/>}/>
          <Route path="/lessen" element={<Lessen/>}/>
          <Route path="/locaties" element={<Locaties/>}/>
          <Route path="/media" element={<Media/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default App;
