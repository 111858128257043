import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive'
import { slide as Menu } from 'react-burger-menu'
import { useState } from 'react';
import hapkido_logo from './hapkido_logo.jpeg';
import burger_icon from './burger_icon.png';
import header from './header.png';
import './index.css';

function Navigation() {
  const [isOpen, setOpen] = useState(false)

  const handleIsOpen = () => {
    setOpen(!isOpen)
  }

  const closeSideBar = () => {
    setOpen(false)
  }
  return (
    <>
    <MediaQuery minWidth={700}>
      <div style={{height:'64px', width:'100%', display:'block'}}></div>
      <nav
        className="flex-wrap items-center text-base justify-center"
        style={{padding:'12px 0px', boxShadow: 'rgb(74 74 74 / 28%) 0px 2px 4px', marginBottom:'10px', position:'fixed', top:'0px', left:'0px', width:'100%',backgroundColor: 'white'}}
      >
        <img src={hapkido_logo} alt="Kum Kam Yi Kon" style={{display:'inline', margin: '0px 18px'}}/>
        <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/">Home</NavLink>
        <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/instructeurs">Instructeurs</NavLink>
        <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/lessen">Lessen</NavLink>
        <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/locaties">Locaties</NavLink>
        <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/media">Media</NavLink>
        <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/contact">Contact</NavLink>
      </nav>
    </MediaQuery>
    <MediaQuery maxWidth={700}>
      <div style={{height:'54px', width:'100%', display:'block'}}></div>
      <div style={{height:'54px', backgroundColor:'white', position:'fixed', width:'100%', top:'0px', boxShadow: 'rgb(74 74 74 / 28%) 0px 2px 4px'}}>
      <NavLink to="/" style={{textDecoration:'none'}}>
        <img src={hapkido_logo} alt="Kum Kam Yi Kon" style={{position:'fixed',top:'7px', left:'8px'}}/>
        <p className="text-2xl font-extrabold dark:text-white" style={{padding:'11px 12px',margin:'0px 46px'}}>Kum Kam Yi Kon</p>
      </NavLink>
      {/* <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/"><img src={logo} alt="Kum Kam Yi Kon"/></NavLink> */}
        <Menu
          right
          noOverlay
          customBurgerIcon={ <img src={burger_icon} alt="menu"/> }
          isOpen={isOpen}
          onOpen={handleIsOpen}
          onClose={handleIsOpen}
          className="mobile_menu"
        >
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/" onClick={closeSideBar}>Home</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/instructeurs" onClick={closeSideBar}>Instructeurs</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/lessen" onClick={closeSideBar}>Lessen</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/locaties" onClick={closeSideBar}>Locaties</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/media" onClick={closeSideBar}>Media</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/contact" onClick={closeSideBar}>Contact</NavLink>
        </Menu>
      </div>
    </MediaQuery>
    <img src={header} alt="Kum Kam Yi Kon"/>
    </>
  );
}
  
export default Navigation;
