import gradaties_hapkido from './gradaties_hapkido.png';
import gradaties_karate from './gradaties_karate.png';

const Lessen = () => {
    return (
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center" style={{display:"block"}}>
           <h2 className="text-4xl font-extrabold dark:text-white" style={{marginBottom:'14px'}}>
                Lessen
            </h2>
            <p className="text-lg">
                <span className="font-extrabold">Dinsdag (Vroondaal - Monster)</span><br/>
                18:45 - 19:45 - Hapkido<br/>
                20:00 - 21:00 - Karate<br/>
                <br/>
                <span className="font-extrabold">Donderdag (Smash - Monster)</span><br/>
                18:45 - 19:45 - Karate<br/>
                20:00 - 21:00 - Hapkido<br/>
                <br/>
                <span className="font-extrabold">Zaterdag (De Kazerne - Moerwijk)</span><br/>
                09:45 - 10:45 - Hapkido<br/>
                11:00 - 12:00 - Karate<br/>
            </p>
            <p className="my-4 text-lg text-gray-800">
                Alle lessen zijn gecombineerd voor Jeugd en Volwassenen.<br/>
            </p>
            <h2 className="text-xl font-extrabold dark:text-white py-4">
            Gradaties karate
            </h2>
            <img src={gradaties_karate} alt="gradaties karate"/>
            <h2 className="text-xl font-extrabold dark:text-white py-4">
                Gradaties Hapkido
            </h2>
            <img src={gradaties_hapkido} alt="gradaties hapkido"/>
        </div>
    )
}

export default Lessen;
