import MediaQuery from 'react-responsive'
import facebook from './facebook.svg';
import './index.css';

function Footer() {
    return (
      <>
      {/* <MediaQuery minWidth={700}> */}
      {/* <MediaQuery> */}
        <div style={{height:'64px', width:'100%', display:'block'}}></div>
        <nav
          className="flex-wrap items-center text-base justify-center"
          style={{padding:'8px 18px', boxShadow: 'rgb(74 74 74 / 28%) 0px 0px 4px', marginTop:'10px', position:'fixed', bottom:'0px', left:'0px', width:'100%',backgroundColor: 'white'}}
        >
          <p className=" text-base dark:text-white" style={{display:'inline'}}>© {(new Date().getFullYear())} - Kum Kam Yi Kon</p>
          <p style={{display:'inline',float:'right'}}>
            <a href="https://www.facebook.com/KumKamYiKon/?locale=nl_NL" target="_blank" rel="noreferrer">
              <img src={facebook} style={{height:'24px'}} alt="Kum Kam Yi Kon op Facebook"/>
            </a>
          </p>
          {/* <img src={hapkido_logo} alt="Kum Kam Yi Kon" style={{display:'inline', margin: '0px 18px'}}/> */}
          {/* <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/">Home</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/instructeurs">Instructeurs</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/lessen">Lessen</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/locaties">Locaties</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/media">Media</NavLink>
          <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/contact">Contact</NavLink> */}
        </nav>
      {/* </MediaQuery> */}
      <MediaQuery maxWidth={700}>
        {/* <div style={{height:'48px', width:'100%', display:'block'}}></div> */}
        {/* <div style={{height:'48px', backgroundColor:'white', position:'fixed', width:'100%', top:'0px', boxShadow: 'rgb(74 74 74 / 28%) 0px 2px 4px'}}> */}
        {/* <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/"> */}
          {/* <img src={hapkido_logo} alt="Kum Kam Yi Kon" style={{position:'fixed',top:'4px', left:'8px'}}/> */}
          {/* <p className="text-2xl font-extrabold dark:text-white" style={{padding:'8px 12px',marginLeft:'46px'}}>Kum Kam Yi Kon</p> */}
        {/* </NavLink> */}
        {/* <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/"><img src={logo} alt="Kum Kam Yi Kon"/></NavLink> */}
          {/* <Menu right noOverlay customBurgerIcon={ <img src={burger_icon} alt="menu"/> }>
            <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/">Home</NavLink>
            <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/instructeurs">Instructeurs</NavLink>
            <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/lessen">Lessen</NavLink>
            <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/locaties">Locaties</NavLink>
            <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/media">Media</NavLink>
            <NavLink className="text-base mr-5 hover:text-gray-900" activeClassName="active" to="/contact">Contact</NavLink>
          </Menu> */}
        {/* </div> */}
      </MediaQuery>
      </>
    );
  }
  
export default Footer;
