import erik from './erik2.jpg';
import kim from './kim2.jpg';
import jurgen from './jurgen2.jpg';

const Media = () => {
    return (
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center" style={{display:"block"}}>
           <h2 className="text-4xl font-extrabold dark:text-white">
                Media
            </h2>
            <p className="my-4 text-lg text-gray-800">
                <div class="grid grid-cols-3 gap-2">
                    <div><img src={kim} alt="Kwanjangnim Kim Hack Hee"/></div>
                    <div><img src={erik} alt="Sabumnin Erik Gijsbertsen"/></div>
                    <div><img src={jurgen} alt="Kyosanim Jurgen van den Berg"/></div>
                </div>
            </p>
        </div>
    )
}

export default Media;
