import MediaQuery from 'react-responsive'

const Instructeur = ({props}) => {
    return (
        <div style={{display:'block'}}>
                <MediaQuery minWidth={700}>
                    <img src={props.img_groot} alt={props.name} style={{width:'570px',margin:'0 auto'}}/>
                </MediaQuery>
                <MediaQuery maxWidth={700}>
                    <img src={props.img} alt={props.name} style={{margin:'0 auto'}}/>
                </MediaQuery>

            <h2 className="text-2xl font-extrabold dark:text-white py-4" style={{paddingBottom:'14px'}}>
                {props.name}
            </h2>
            <ul>
                {props.achievements.map((achievement) => (
                    <li className="w-full text-base text-gray-800 py-1" key={achievement}>{achievement}</li>
                ))}
                </ul>
            <div className="my-10" style={{borderBottom:'1px solid rgb(31 41 55 / 15%)', boxShadow:'rgba(0, 0, 0, 0.10) 0px 1px 2px'}}></div>
        </div>
    )
}

export default Instructeur;
