const Contact = () => {
    return (
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center" style={{display:"block"}}>
           <h2 className="text-4xl font-extrabold dark:text-white">
                Contact
            </h2>
            <p className="my-4 text-lg text-gray-800">
                <span style={{fontWeight:'bold', display:'block'}}>Kum Kam Yi Kon</span>
                E.A. Gijsbertsen<br/>
                Email: <a href="mailto:Erik_A_G@hotmail.com" style={{textDecoration:'underline'}}>Erik Gijsbertsen</a><br/>
            </p>
            <p className="my-4 text-lg text-gray-800">
                <span style={{fontWeight:'bold', display:'block'}}>Daehan Hapkido Bond Nederland | DHBN</span>
                E.A. Gijsbertsen<br/>
                Mobiel: <a href="tel:0642091659" style={{textDecoration:'underline'}}>06-42091659</a><br/>
            </p>
            <p className="my-4 text-lg text-gray-800">
                <span style={{fontWeight:'bold', display:'block'}}>Overige websites</span>
                {/* <a href="http://www.daehanhapkido.nl" data-type="URL">Daehan Hapkido Bond Nederland</a><br/> */}
                <a href="http://www.umyangkwon.nl" style={{textDecoration:'underline'}}>Umyangkwon</a><br/>
                <a href="http://www.jinmukwan.nl" style={{textDecoration:'underline'}}>Jinmukwan</a><br/>
                <a href="http://www.hapki.net" style={{textDecoration:'underline'}}>Kwan Jan Nim Kim Hack Hee</a><br/>
                <a href="http://www.kyokushin.nl" style={{textDecoration:'underline'}}>Karateschool Ruud Muller</a><br/>
                <a href="http://www.dojokamakura.com" style={{textDecoration:'underline'}}>Dojo Kamakura</a><br/>
                <a href="http://www.NKF-info.nl" style={{textDecoration:'underline'}}>NKF Ruud Muller dojo</a>   <br/>
            </p>
        </div>
    )
}

export default Contact;
