import erik from './erik.png';
import erik_groot from './erik_groot.png';
import erik_karate from './erik_karate.png';
import erik_karate_groot from './erik_karate_groot.png';
import kim from './kim.png';
import kim_groot from './kim_groot.png';
import jurgen from './jurgen.png';
import jurgen_groot from './jurgen_groot.png';
import mihail from './mihail.png';
import mihail_groot from './mihail_groot.png';

import Instructeur from './Instructeur';

const Instructeurs = () => {
    const instructeur_list = [
        {
            name:'Sabumnin Erik Gijsbertsen',
            achievements: [
                '4de Dan Daehan Hapkido Han Moo Kwan',
                '2de Dan Hapkido WHFNZ',
                'Penningmeester Daehan Hapkido Bond',
                'Oprichter Kum Kam Yi Kon (2008)',
            ],
            img: erik,
            img_groot: erik_groot,
        },
        {
            name:'Sensei Erik Gijsbertsen',
            achievements: [
                '4e Dan Kyokushin Karate Nederlandse Kyokushin Federatie olv Shihan R.Muller',
            ],
            img: erik_karate,
            img_groot: erik_karate_groot,
        },
        {
            name:'Kwanjangnim Kim Hack Hee',
            achievements: [
                '7de Dan Daehan Hapkido, van de Han Moo Kwan (GM Song Young Ki, 9de dan)',
                'Secretaris van de examencommissie in Busan',
            ],
            img: kim,
            img_groot: kim_groot,
        },
        {
            name:'Kyosanim Jurgen van den Berg',
            achievements: [
                '3e Dan Daehan Hapkido Han Moo Kwan',
                '1e Dan Judo',
                'Secretaris Dae Han Hapkido Bond Nederland',
            ],
            img: jurgen,
            img_groot: jurgen_groot,
        },
        {
            name:'Senpai en Kyosanim Mihail Bogdonov',
            achievements: [
                '1e Dan Kyokushin Karate NKF',
                '2e Dan Daehan Hapkido Han Moo Kwan',
            ],
            img: mihail,
            img_groot: mihail_groot,
        },
    ]

    return (
        <div className="p-5 columns-1" style={{display:"block"}}>
            {instructeur_list.map((instructeur) => (
                <Instructeur props={instructeur} key={instructeur.name}/>
            ))}
        </div>
    )
}

export default Instructeurs;
