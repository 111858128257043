const Locaties = () => {
    return (
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center" style={{display:"block"}}>
            <h2 className="text-4xl font-extrabold dark:text-white">
                Locaties
            </h2>
            <p className="my-4 text-lg text-gray-800">
                De lessen worden gegeven op de volgende locaties
            </p>
            <p className="my-4 text-lg text-gray-800">
                <a href="https://www.google.com/maps/dir/?api=1&destination=Kum+Kam+Yi+Kon" target="_blank" rel="noreferrer">
                    <span style={{fontWeight:'bold', display:'block'}}>Locatie De Kazerne</span>
                    <span style={{textDecoration:'underline'}}>Erasmusweg 8L - Moerwijk</span><br/>
                    2532 CP Den Haag<br/>
                </a>
            </p>
            <p className="my-4 text-lg text-gray-800">
                <a href="https://www.google.com/maps/dir/?api=1&destination=Kindcentrum+Vroondaal" target="_blank" rel="noreferrer">
                    <span style={{fontWeight:'bold', display:'block'}}>Locatie Vroondaal</span>
                    <span style={{textDecoration:'underline'}}>Bertrand Russelllaan 16</span><br/>
                    2553 DP Den Haag<br/>
                </a>
            </p>
            <p className="my-4 text-lg text-gray-800">
                <a href="https://www.google.com/maps/dir/?api=1&destination=Tafeltennisvereniging+Smash" target="_blank" rel="noreferrer">
                    <span style={{fontWeight:'bold', display:'block'}}>Locatie Smash</span>
                    Rinus Nederpelzaal<br/>
                    <span style={{textDecoration:'underline'}}>Jan Steenstraat 2</span><br/>
                    2681 MG  Monster<br/>
                </a>
            </p>
        </div>
    )
}

export default Locaties;
