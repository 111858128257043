import kyokushinkai from './kyokushinkai.png'
import hapkido_logo_big from './hapkido_logo_big.jpeg'

const Home = () => {
    return (
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <h2 className="text-4xl font-extrabold dark:text-white">
                Dojang Daehan Hapkido Han Moo Kwan en Dojo Kyokushin Karate NKF
            </h2>
            <p className="my-4 text-lg text-gray-800">
                Welkom op de website van de sportschool Kum Kam Yi Kon.<br/>
                Kum kam Yi Kon is in 2008 opgericht door Erik Gijsbertsen en is met de Daehan Hapkido sinds 3 november 2008 aangesloten bij de Daehan Hapkido Bond Nederland (DHBN), onderdeel van de Korean Hapkido Federation olv Kwanjannim Kim Hack Hee.
            </p>
            <p className="my-4 text-lg text-gray-800">
                <img src={hapkido_logo_big} className="float-left" style={{margin:'6px 16px 0px 0px'}} alt="Kum Kam Yi Kon"/>
                De naam Hapkido is ontstaan in de jaren 1950 en kwam oorspronkelijk van Ji Han Jae. Aanvankelijk bestond Hapkido eerder onder de naam Yu Kwon Sool, de originele gevechtskunst zoals ze door Choi Yong Sool werd aangeleerd. De traptechnieken werden nadien in een latere fase aan het systeem toegevoegd. In de periode na de tweede wereld oorlog ontstond er in Korea een ware boom in gevechtskunsten. Het was tijdens de Japanse bezetting niet toegestaan om de Koreaanse gevechtskunsten te beoefenen. De Koreaanse vechtkunsten werden tijdens de japanse bezetting beoefend in familie kring, in de bergen en in de kloosters. Alhoewel de Japanse bezetting een grote invloed heeft gehad op de Koreaanse gevechtskunsten zijn traditionele technieken zoals bijvoorbeeld Taekkyon, boeddhistische en taoïstische stijlen bewaard gebleven. Deze traditionele stijlen hebben hun stempel gedrukt op de gevechtskunsten van na de tweede wereldoorlog. De <a href="https://www.umyangkwon.nl/kisul.html" target="_blank" rel="noreferrer" style={{textDecoration:'underline'}}>Ki Sul</a> (Ki ontwikkelen en toepassen) is hierdoor een belangrijk onderdeel.
            </p>
            <p className="my-4 text-lg text-gray-800">
                Hapkido kent naast valbreken, traptechnieken, stoottechnieken, slagen, gewrichtsklemmen, drukpunttechnieken ook wapentechnieken. Bij een aanval wordt zoveel mogelijk gebruik gemaakt van de kracht en beweging van de tegenstander om hem uit balans te brengen en/of te controleren. Pas hierna wordt de tegenaanval ingezet.
            </p>
            <p className="my-4 text-lg text-gray-800 font-bold w-full">
                KYOKUSHIN Karate
            </p>
            <p className="my-4 text-lg text-gray-800">
                Vanaf 30 oktober 2014 wordt er ook Kyokushin Karate gegeven.
            </p>
            <p className="my-4 text-lg text-gray-800 w-full">
                <img src={kyokushinkai} alt="kyokushinkai" className="float-left" style={{margin:'20px 6px 0px -10px'}}/>
                <p className="text-lg text-gray-800">
                    <b>Kyoku</b> betekent het uiterste.<br/>
                    Dit betekent dat je het maximale uit elke situatie wil halen door door te zetten en je geheel te geven om zo tot het uiterste te gaan voor dat wat je wilt bereiken.<br/><br/>
                    <b>Shin</b> betekent de waarheid.<br/>
                    Dit betekent dat je alleen met een ijzeren discipline en juiste mentaliteit dit kan bereiken. Je zal zonder marchanderen de realiteit tegemoet moeten treden, en wanneer je dit kunt opbrengen, sterkt dit je in je zelfvertrouwen.<br/><br/>
                    <b>Kai</b> betekent samenwerken.<br/>
                    Dit staat voor het samenwerken met elkaar in de juiste verhouding en altijd met het nodige respect voor ieders standpunt. Respect is een essentieel punt in de relatie tot elk mens.
                </p>
            </p>
            <p className="my-4 text-lg text-gray-800">
                De lessen bestaan uit warming-up, rek- en strekoefeningen, kracht, conditie, weerbaarheid, techniek, kata, kihon en sparren.
            </p>
            <p className="my-4 text-lg text-gray-800">
                Sportschool Kum Kam Yi Kon is ontstaan vanuit de passie voor martial arts. Doel is om Daehan Hapido en Kyokushin Karate te blijven beoefenen en de opgedane kennis te verspreiden.
            </p>
        </div>
    )
}

export default Home;
